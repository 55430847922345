
console.log('ENV',process.env);


export let BASE_URL = process.env.REACT_APP_BASE_URL
export let BASE_API_URL = process.env.REACT_APP_BASE_API_URL
export let MINI_API_URL = process.env.REACT_APP_MINI_API_URL
export let ENV_NAME = process.env.REACT_APP_ENV_NAME
export const location = window && window.location

export const hostname = `${location.protocol}//${location.hostname}`



console.log('hostname ===> ', hostname)
console.log('BASE_URL ===> ', BASE_URL)
console.log('BASE_API_URL ===> ', BASE_API_URL)
console.log('MINI_API_URL ===> ', MINI_API_URL)
